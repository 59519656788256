import React from "react"
import "./Portfolio.css"
import { Typewriter } from "react-simple-typewriter"
 import Portfolio_data from "./Portfolio_data"

const Portfolio = () => {
  return (
    <>
      <section className='Portfolio top' id='portfolio'>
        <div className='container'>
          <div className='heading text-center '>
            <h4>VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK</h4>
            <h3>My Portfolio</h3>
          </div>

          <div className='content'>
          <div className="container">
                <div className="row" style={{ width: "100%" }}>
                  
            {/* {Portfolio_data.map((value, index) => {
              return <Card key={index} image={value.image} category={value.category} totalLike={value.totalLike} title={value.title} />
            })} */}
  {Portfolio_data.map((value, index) => (
    <div className="col-lg-6" >
<div className='box btn_shadow'>

    <div key={index} className='portfolio-item'>
    <h2>{value.title}</h2>
    <span>{value.category}</span>
     
      <div className='img'>
      <a href={value.url} target="_blank">
        <img src={value.image} alt='' />
        </a>
      </div>
      <div className='category d_flex'>
       
      </div>
      <div className='title'>
        <h2> 
        <span>
  <Typewriter
    words={[`${value.language}`, `${value.language}`]}
    loop
    cursor
    cursorStyle='.'
    typeSpeed={200}
    deleteSpeed={50}
    delaySpeed={1000}
  />
</span> 
        </h2>
        <p>{value.description}</p>
        <a href={value.url} target="_blank" className='arrow'>
          <i className='fas fa-arrow-right'></i>
        </a>
      </div>
    </div>
    </div>
</div>
 ))}
</div>
</div>
            
            
          </div>
        </div>
      </section>
    </>
  )
}

export default Portfolio