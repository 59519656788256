const Portfolio_data = [
    {
      id: 1,
      title: "CRM(Customer relationship management)",
      language:"Php,Bootstrap,Ajax,MySql",
      image:"https://vedantaiasacademy.co.in/port/my/images/1697873685Screenshot%202023-10-21%20125459.png",
      url:"https://crm.afte.in/",
      description:"Customer Relationship Management (CRM)manages customer interactions to enhance customer experience.It is used like admission,enquiry ,bulk enquiry,finance etc web application.",
      category: "DEVELOPMENT",
    },
    {
      id: 2,
      title: "EMS (Employee management system)",
      language:" Php,Bootstrap,Ajax and jquery ,MySql",
      image:"https://vedantaiasacademy.co.in/port/my/images/1697873379Screenshot%202023-10-21%20125557.png",
      url:"https://ems.vgigroup.co.in/login.php",
      description:"It is a employee or staff attandance,leave,single work & team work and daily work report based web application.It is used team work chat or single user chat discussion",
      category: "DEVELOPMENT",
    },
    {
      id: 3,
      title: "AFTE Library",
      language:" Php(codeinighter),Bootstrap,Ajax and jquery,MySql database",
      image:"https://vedantaiasacademy.co.in/port/my/images/1697873908Screenshot%202023-10-21%20130730.png",
      url:"https://crm.afte.in/library/Admin/index",
      description:"Maintain Books Stockes, Issuing Books To Students And Maintain Issue Books Details.",
      category: "DEVELOPMENT",
    },
    {
      id: 4,
      title: " Top Faculty",
      language:"Php,Bootstrap,Ajax and jquery ,MySql",
      image:"https://vedantaiasacademy.co.in/port/my/images/1697874266Screenshot%202023-10-21%20131320.png",
      url:" http://topfaculty.co.in/",
      description:"It is used student booked teacher and learn any classes for home coaching or online classes.",
      category: "DEVELOPMENT",
    },
    {
      id: 5,
      title: "ECOMMERCE",
      language:"Php(codeinighter),Bootstrap,Ajax and jquery,MySql database",
      image:"https://vedantaiasacademy.co.in/port/my/images/1697874700Screenshot%202023-10-21%20131942.png",
      url:"#",
      description:"It is used eccomerce product website.",
      category: "DEVELOPMENT",
    },
    {
      id: 6,
      title: " VGI Product Stocks",
      language:"Php,Bootstrap,Ajax and jquery ,MySql",
      image:"https://vedantaiasacademy.co.in/port/my/images/1708541195Screenshot%202024-02-22%20001344.png",
      url:" https://ems.vgigroup.co.in/vgi_stock/",
      description:"It is used for  maintain product Stock.",
      category: "DEVELOPMENT",
    },
  ]
  export default Portfolio_data