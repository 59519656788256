import React from "react"
import "./Resume.css"
// import ResumeApi from "./ResumeApi"
// import Card from "./Card"

const Resume = () => {
  
  return (
    <>
      <section className='Resume' id='resume'>
        <div className='container top'>
          <div className='heading text-center'>
             <h1>My Resume</h1>
          </div>

          <div className='content-section mtop d_flex'>
            <div className='left'>
              <div className='heading'>
                <h4>2007-2010</h4>
                <h1>Education Quality</h1>
              </div>

              <div className='content'>
                {/* {ResumeApi.map((val, id) => {
                  if (val.category === "education") {
                    return <Card key={id} title={val.title} year={val.year} rate={val.rate} desc={val.desc} />
                  }
                })} */}

                <div className='box btn_shadow2'>
                  <div className='title_content d_flex'>
                    <div className='title'>
                       <h4>Bachelor of Technology</h4>
                      <span>Dcrust University(2017 - 2021)</span>
                      <p>Sonipat Haryana</p>
                    </div> 
                    <div>                    
                      <button className='btn_shadow '>Graduation</button>
                      </div>  
                  </div>
                 </div>
                 <div className='box btn_shadow2'>
                  <div className='title_content d_flex'>
                    <div className='title'>
                       <h4>Senior Secondary School Certificate</h4>
                      <span>UP Board(2017)</span>
                      <p>Uttar Pradesh</p>
                    </div> 
                    <div>                    
                      <button className='btn_shadow '>12<sup>th</sup></button>
                      </div>  
                  </div>
                 </div>
                 <div className='box btn_shadow2'>
                  <div className='title_content d_flex'>
                    <div className='title'>
                      <h4>Secondary School Certificate</h4>
                      <span>UP Board(2015)</span>
                      <p>Uttar Pradesh</p>
                    </div> 
                    <div>                    
                      <button className='btn_shadow '>10<sup>th</sup></button>
                      </div> 
                  </div>
                 </div>

              </div>
            </div>


            <div className='left'>
              <div className='heading'>
                <h4>2021-2023</h4>
                <h1>SKILLS</h1>
                
              </div>

              <div className='box btn_shadow3' style={{backgroundImage: 'url("https://www.toptal.com/designers/subtlepatterns/uploads/hypnotize.png")'}}>
                 
                  <div style={{ marginBottom: 6 }}>
    <b>HTML & CSS</b>
  </div>
  <div className="progress">
    <div className="bar">97%</div>
  </div>
  <div style={{ marginBottom: 6, marginTop: 13 }}>
    <b>BOOTSTRAP</b>
  </div>
  <div className="progress">
    <div className="bar spanish">82%</div>
  </div>
  <div style={{ marginBottom: 6, marginTop: 13 }}>
    <b>JAVASCRIPT</b>
  </div>
  <div className="progress">
    <div className="bar italian">70%</div>
  </div>
  <div style={{ marginBottom: 6, marginTop: 13 }}>
    <b>Ajax & JQuery
</b>
  </div>
  <div className="progress">
    <div className="bar french">65%</div>
  </div>
  <div style={{ marginBottom: 6, marginTop: 13 }}>
    <b>PHP</b>
  </div>
  <div className="progress">
    <div className="bar croatian">80%</div>
  </div>
  <div style={{ marginBottom: 6, marginTop: 13 }}>
    <b>CODEIGNITER</b>
  </div>
  <div className="progress">
    <div className="bar italian">70%</div>
  </div>
  <div style={{ marginBottom: 6, marginTop: 13 }}>
    <b>MY SQL
</b>
  </div>
  <div className="progress">
    <div className="bar russian">67%</div>
  </div>
 
  <div style={{ marginBottom: 6, marginTop: 13 }}>
    <b>Rest API</b>
  </div>
  <div className="progress">
    <div className="bar albanian">65%</div>
  </div>
                  <hr />
                 </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  )
}

export default Resume