import React from "react"
import logo from "./pic/man.png"
import HomeIcon from '@mui/icons-material/Home';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import WorkIcon from '@mui/icons-material/Work';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ContactsIcon from '@mui/icons-material/Contacts';

const Footer = () => {
  return (
    <>
      
        {/* <div class="shadow-lg p-3 mb-5 bg-white rounded"> */}
        {/* <div className='conatiner text-center  '>
          
          <p>© 2024. All rights reserved by Sachin Portfolio</p>
        </div> */}
        
        <nav class="nav">
  <a href="#home" class="nav__link nav__link--active">
     <span class="nav__text"><HomeIcon /></span>
  </a>
  <a href="#about" class="nav__link nav__link--active">
     <span class="nav__text"><PersonPinIcon /></span>
  </a>
  <a href="#portfolio" class="nav__link nav__link--active">
     <span class="nav__text"><WorkIcon /> </span>
  </a>
   <a href="#resume" class="nav__link nav__link--active">
     <span class="nav__text"><LibraryBooksIcon /> </span>
  </a>  
  <a href="#contact" class="nav__link nav__link--active">
     <span class="nav__text"><ContactsIcon /> </span>
  </a>
</nav>

        {/* </div> */}
       
      


      
    </>
  )
}

export default Footer