import React from 'react'
import './App.css';
import Header from './components/Head/Header'
import Home from './components/Hero/Home';
import About from './components/About/About';
import Portfolio from './components/Portfolio/Portfolio';
import Resume from './components/Resume/Resume';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer';
const App = () => {
  return (
    < >
      <Header/>
      <Home/>
      <About/>
      <Portfolio/>
      <Resume/>
      <Contact/>
      <Footer/>


    </>
  )
}

export default App
