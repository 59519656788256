import React from "react";
import "./About.css";
// import data from "./Features-Api"
// import Card from "./Card"

const About = () => {
   return (
    <>
      <section className="features top" id="about">
        <div className="container">
          <div className="heading">
            <h4>About Me-</h4>
            <h3>LEARN MORE ABOUT ME</h3>
          </div>

          <div className="content">
            <div className="box btn_shadow2">
              <div className="container">
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-lg-6" style={{backgroundImage: 'url("../sac.jpg")'}}>
                    {/* <img
                      src="https://img.icons8.com/glyph-neue/64/000000/polyline.png"
                      alt=""
                    />
                    <h2>Personal Portfolio April</h2>
                    <p>
                      It uses a dictionary of over 200 Latin words, combined
                      with a handful of model sentence.
                    </p>
                    <a href="">
                      <i class="fas fa-arrow-right"></i>
                    </a> */}
                  </div>
                  <div className="col-lg-6">
                    <div className="mi-about-content">
                      <h3>
                        I am <span className="color-theme">SACHIN</span>
                      </h3>
                      <p>
                        I am a PHP, web developer. I can provide clean code
                        and pixel perfect design.To contribute for the betterment of an organization that also gives me an opportunity to integrate my knowledge Personal skills and also give me a platform to grow with the organization.
                      </p>
                      <ul>
                        <li>
                          <b>Full Name :</b> Sachin Kumar
                        </li>
                        <li>
                          <b>Age :</b> 22 Years
                        </li>
                        <li>
                          <b>Nationality :</b> Indian
                        </li>
                        <li>
                          <b>Languages :</b> English, Hindi
                        </li>
                        <li>
                          <b>Address :</b> Rohini Sec-14, New Delhi, 110085
                        </li>
                        
                      </ul>
                      <a className="mi-button" href='cv.pdf' download="cv.pdf" target="_self">Download CV</a>
                      {/* <a className="mi-button" href='cv.pdf' download="cv.pdf">
                        <button className="btn_shadow">Resume</button>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
