// import React, { useState } from "react"
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
// import contact1 from "./contact1.png"
import Swal from 'sweetalert2';
import "./Contact.css"

const Contact = () => { 
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_j5sznmd', 'template_2yousr6', form.current, {
        publicKey: 'WJOFVKbHdLyy3syO8',
      })
      
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

      Swal.fire({
        icon: 'success',
        title: 'Message Sent Successfully!',
        text: 'Thank you for contacting us.',
      });
      e.target.reset()
  };
  return (
    <>
      <section className='Contact' id='contact'>
        <div className='container top'>
          <div className='heading text-center'>
            <h4>CONTACT</h4>
            <h3>Contact With Me</h3>
          </div>

          <div className='content d_flex'>
            <div className='left'>
              <div className='box box_shodow'>
                <div className='img'>
                  <img src='https://graintechindia.com/img/contact_us.jpeg' style={{width:'100%'}} alt='' />
                </div>
                <div className='details'>
                  <h1>SACHIN</h1>
                  <p>Phone: +9140920656</p>
                  <p>Email: sachinrajput8929@gmail</p> 
                  <p>Address:Rohini Sec-14 New Delhi 110085</p>
                  {/* <span>FIND WITH ME</span>
                  <div className='button f_flex'>
                    <button className='btn_shadow'>
                      <i className='fab fa-facebook-f'></i>
                    </button>
                    <button className='btn_shadow'>
                      <i className='fab fa-instagram'></i>
                    </button>
                    <button className='btn_shadow'>
                      <i className='fab fa-twitter'></i>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            <div className='right box_shodow'>
              <form ref={form} onSubmit={sendEmail}>
                 
                  <div className='input  '>
                    <span>YOUR NAME</span>
                    <input type='text' name='fullname'  />
                  </div>
                  <div className='input'>
                    <span>PHONE NUMBER </span>
                    <input type='number' name='phone'   />
                  </div>
               
                <div className='input'>
                  <span>EMAIL </span>
                  <input type='email' name='email'   />
                </div>
                <div className='input'>
                  <span>SUBJECT </span>
                  <input type='text' name='subject'   />
                </div>
                
                <button type='submit' className='btn_shadow'>
                  SEND MESSAGE <i className='fa fa-long-arrow-right'></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact